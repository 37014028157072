<style lang="scss" scoped>
@import "@styles/_variables";

.modulos-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
}
.modulos-wrapper .acesso-modulo{
    text-align: center;
    vertical-align: top;
    width: 20%;
    padding: 0 1rem 2rem;
}
@media (max-width: 480px){
    .modulos-wrapper .acesso-modulo{
        width: 50% !important;
    }
}
@media (min-width: 481px) and (max-width: 751px){
    .modulos-wrapper .acesso-modulo{
        width: 33% !important;
    }
}
@media (min-width: 752px) and (max-width: 1024px) {
    .modulos-wrapper .acesso-modulo{
        width: 25% !important;
    }
}
.modulos-wrapper .acesso-modulo:hover{
    transition: all 300ms ease 0ms;
    border-radius: 12px;
}
.modulos-wrapper .acesso-modulo .icon-wrapper{
    background-color: $white;
    background-color: var(--white);
    display: flex;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 100%;
    margin-bottom: 8px;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease 0ms;
}
.modulos-wrapper .acesso-modulo .icon-wrapper .image-icon{
    transition: all 300ms ease 0ms;
}
.modulos-wrapper .acesso-modulo .label{
    max-width: 160px;
    display: inline-block;
    margin: 0 auto;
    color: $dark-blue;
    color: var(--dark-blue);
}
.modulos-wrapper .acesso-modulo:hover .icon-wrapper{
    background-color: $gray-200;
    background-color: var(--gray-200);
}
.modulos-wrapper .acesso-modulo:hover .icon-wrapper .image-icon{
    filter: brightness(0%) invert(100%);
    -ms-filter: "alpha(opacity=40)";
}

.card-content{
    .list-pending{
        .arrow-list-pending{
            position: absolute;
            top: 0;
            left: 0;

            &-color{
                fill: $primary;
                fill: var(--primary);
            }
        }
        p{
            margin-bottom: 12px;
            position: relative;
            padding-left: 35px;
        }
    }
    .image-welcome{
        width: 100vw;
        max-width: 128px;
    }
}
</style>

<template>
	<div class="columns">
		<b-notification
			:closable="false"
			class="notification"
		>
			<b-loading
				v-model="loading"
				:is-full-page="true"
				:can-cancel="false"
			/>
		</b-notification>
		<div
			v-if="!loading"
			class="column is-10-tablet is-offset-1-tablet is-8-fullhd is-offset-2-fullhd"
		>
			<Title>Atividades Complementares</Title>
			<div class="modulos-wrapper">
				<a
					v-for="(acesso, index) in acessos"
					:key="index"
					:href="acesso.href"
					:target="get(acesso, 'target', '_self')"
					class="acesso-modulo"
				>
					<span class="icon-wrapper">
						<Icon
							:file="acesso.icon"
							:size="get(acesso, 'width', 40)"
						/>
					</span>
					<span class="label">{{ acesso.label }}</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@components/Icon';
import Title from '@components/Title';
import { get } from 'lodash';
import http from '@commons/http';

export default {
	name: "Acessos",
	components:{
		Icon,
		Title,
	},
	data() {
		return {
			acessos: [],
			loading: true,
			isFullPage: false
		}
	},
	async created() {
		await this.getAcessosAtividadeComplementares();
	},
	methods:{
		goto() {
			this.$router.push({ name: 'login'});
		},
		setAcessos( data ) {
			this.acessos = data;
			this.loading = false;
		},
		get,
		getAcessosAtividadeComplementares(){
			http.get('/v1/aluno/links-servicos/atividades-complementares').then(({data})=>{
				this.setAcessos( data.message );
			});
		},
	}
}
</script>
